import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NavBar from "./NavBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
axios.defaults.withCredentials = true;

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`https://api2.wiki-inf.cloud/login`, {
        username: username,
        password: password,
      });
      toast.success("Login berhasil!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });

      // Menunda navigasi selama 5 detik
      setTimeout(() => {
        navigate("/admin");
      }, 2000);
    } catch (error) {
      if (error.response) {
        const errorMsg = error.response.data.msg;
        toast.error(errorMsg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, 
        });
      }
    }
  };

  return (
    <div className="body">
      <NavBar />
      <div className="Auth-form-container">
        <form onSubmit={handleLogin} className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Log In</h3>
            <div className="form-group mt-3">
              <input
                type="text"
                className="form-control mt-1"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <input
                className="form-control mt-1"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Login
              </button>
              <ToastContainer />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;

import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import KategoriCard from "./KategoriCard";
axios.defaults.withCredentials = true;

function KurikulumComponent(props) {
  const [details, setDetails] = useState([]);

  // Gunakan useEffect untuk mengambil data dari api2 saat komponen dimuat
  useEffect(() => {
    axios
      .get("https://api2.wiki-inf.cloud/artikel") // Ganti URL sesuai dengan api2 Anda
      .then((response) => {
        // Simpan data yang diterima dari api2 ke dalam state "details"
        setDetails(response.data);
      })
      .catch((error) => {
        console.error("Gagal mengambil data:", error);
      });
  }, []); // Tambahkan dependensi kosong agar hanya dijalankan sekali saat komponen dimuat

  // Melakukan filtering berdasarkan properti 'cat'
  const filteredData = details.filter((info) => info.cat === "kurikulum");
  const limitedData = filteredData.slice(0, 6); // Ambil 6 data pertama

  const filtered = limitedData.map((info) => (
    <KategoriCard key={info.id} info={info} />
  ));
  return (
    <div className="bg-blue">
      <Container className="kat-container">
        <Row>
          <Col className="text-center">
            <h1 className="judul">KURIKULUM</h1>
          </Col>
        </Row>
        <Row>
          <div className="card-container">{filtered}</div>
        </Row>
      </Container>
    </div>
  );
}

export default KurikulumComponent;

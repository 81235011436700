import React, { useEffect, useState } from "react";
import axios from "axios"; 
import { Navigate, useParams } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
axios.defaults.withCredentials = true;

function Halaman(props) {
  const [data, setData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`https://api2.wiki-inf.cloud/artikel/${id}`);
        setData(response.data);
      } catch (error) {
        console.error("Gagal mengambil data:", error);
      }
    }

    fetchData();
  }, [id]);

  const Edit = () => {
    navigate(`/edithalaman/${id}`);
  };

  return (
    <div>
      {data ? (
        <div className="body">
          <div className="sticky">
          <NavBar />
          </div>
          <div className="halaman">
            <div class="container">
              <div class="main-content">
                <h1 className="mv4">{data.title}</h1>
                <div  dangerouslySetInnerHTML={{ __html: data.desc }} />
                {/* <Button variant="outline-primary" onClick={Edit}>Edit</Button> */}
              </div>
              <div class="sidebar"></div>
            </div>
          </div>

          <div style={{ marginTop: "250px" }}>
            <Footer />
          </div>
        </div>
      ) : (
        <p>Data tidak ditemukan.</p>
      )}
    </div>
  );
}

export default Halaman;

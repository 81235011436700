import React, { useState, useEffect } from "react";
import SearchList from "./SearchList";
import axios from 'axios';
import logo from "../assets/logoinf.png";
import "bootstrap/dist/css/bootstrap.min.css";
axios.defaults.withCredentials = true;

function Search() {
  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);

  const [details, setDetails] = useState([]);

  // Gunakan useEffect untuk mengambil data dari api2 saat komponen dimuat
  useEffect(() => {
    axios.get('https://api2.wiki-inf.cloud/artikel') // Ganti URL sesuai dengan api2 Anda
      .then(response => {
        // Simpan data yang diterima dari api2 ke dalam state "details"
        setDetails(response.data);
      })
      .catch(error => {
        console.error('Gagal mengambil data:', error);
      });
  }, []); // Tambahkan dependensi kosong agar hanya dijalankan sekali saat komponen dimuat

  // Filter data dengan kata kunci pencarian
  const filteredInfos = details.filter(info => {
    return (
      info.title.toLowerCase().includes(searchField.toLowerCase()) ||
      info.desc.toLowerCase().includes(searchField.toLowerCase())
    );
  });

  const handleChange = (e) => {
    setSearchField(e.target.value);
    if (e.target.value === "") {
      setSearchShow(false);
    } else {
      setSearchShow(true);
    }
  };

  function searchList() {
    if (searchShow) {
      return (
        <div>
          <SearchList filteredInfos={filteredInfos} />
        </div>
      );
    }
  }

  return (
    <section>
      <div className="container-logo" style={{ paddingTop: "90px" }}>
        <img className="logo" src={logo} alt="logo" />
        <h1 className="judul">Wiki-Inf.</h1>
      </div>
      <input
          className="pa3 w-70 br3 grow b--none bg-white ma2 searchbar"
          type="search"
          placeholder="🔍 Cari informasi apapun disini"
          onChange={handleChange}
      />
      {searchList()}
    </section>
  );
}

export default Search;
